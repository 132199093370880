export default {
  pending: [
    {
      sortable: false,
      text: "Case id",
      align: "center",
      value: "case",
      class: ["table-header"],
      width: 120,
    },
    {
      sortable: false,
      text: "Client",
      value: "client",
      align: "center",
      class: ["table-header"],
      width: 120,
    },
    {
      sortable: false,
      text: "Sales Team",
      align: "center",
      value: "assigned_sales",
      class: ["table-header"],
      width: 130,
    },
    {
      sortable: false,
      text: "Payment Date",
      align: "center",
      value: "payment_date",
      class: ["table-header"],
      width: 121,
    },
    {
      sortable: false,
      text: "Payment Mode",
      align: "center",
      value: "mode",
      class: ["table-header"],
      width: 130,
    },
    {
      sortable: false,
      text: "Amount",
      align: "center",
      value: "amount",
      class: ["table-header"],
      width: 175,
    },
    {
      sortable: false,
      text: "Remarks",
      align: "center",
      value: "remarks",
      class: ["table-header"],
      width: 150,
    },
    {
      sortable: false,
      text: "Documents",
      align: "center",
      value: "document",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Status",
      align: "center",
      value: "status",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Action",
      align: "center",
      value: "action",
      class: ["table-header"],
    },
  ],

  successful: [
    {
      sortable: false,
      text: "Case id",
      align: "center",
      value: "case",
      class: ["table-header"],
      width: 120,
    },
    {
      sortable: false,
      text: "Client",
      value: "client",
      align: "center",
      class: ["table-header"],
      width: 110,
    },
    {
      sortable: false,
      text: "Sales Team",
      align: "center",
      value: "assigned_sales",
      class: ["table-header"],
      width: 130,
    },
    {
      sortable: false,
      text: "Payment Date",
      align: "center",
      value: "payment_date",
      class: ["table-header"],
      width: 121,
    },
    {
      sortable: false,
      text: "Payment Mode",
      align: "center",
      value: "mode",
      class: ["table-header"],
      width: 130,
    },
    {
      sortable: false,
      text: "Amount",
      align: "center",
      value: "amount",
      class: ["table-header"],
      width: 175,
    },
    {
      sortable: false,
      text: "Remarks",
      align: "center",
      value: "remarks",
      class: ["table-header"],
      width: 150,
    },
    {
      sortable: false,
      text: "Documents",
      align: "center",
      value: "document",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Status",
      align: "center",
      value: "status",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Action",
      align: "center",
      value: "action",
      class: ["table-header"],
    },
  ],

  cancelled: [
    {
      sortable: false,
      text: "Case id",
      align: "center",
      value: "case",
      class: ["table-header"],
      width: 120,
    },
    {
      sortable: false,
      text: "Client",
      value: "client",
      align: "center",
      class: ["table-header"],
      width: 110,
    },
    {
      sortable: false,
      text: "Sales Team",
      align: "center",
      value: "assigned_sales",
      class: ["table-header"],
      width: 130,
    },
    {
      sortable: false,
      text: "Payment Date",
      align: "center",
      value: "payment_date",
      class: ["table-header"],
      width: 121,
    },
    {
      sortable: false,
      text: "Payment Mode",
      align: "center",
      value: "mode",
      class: ["table-header"],
      width: 130,
    },
    {
      sortable: false,
      text: "Amount",
      align: "center",
      value: "amount",
      class: ["table-header"],
      width: 175,
    },
    {
      sortable: false,
      text: "Remarks",
      align: "center",
      value: "remarks",
      class: ["table-header"],
      width: 150,
    },
    {
      sortable: false,
      text: "Documents",
      align: "center",
      value: "document",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Status",
      align: "center",
      value: "status",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Cancelled Date",
      align: "center",
      value: "status_date",
      class: ["table-header"],
      width: 150,
    },
    {
      sortable: false,
      text: "Cancelled Reason",
      align: "center",
      value: "reject_reason",
      class: ["table-header"],
      width: 150,
    },
    {
      sortable: false,
      text: "Action",
      align: "center",
      value: "action",
      class: ["table-header"],
    },
  ],
};
