<template>
  <div>
    <div class="d-flex justify-space-between align-start">
      <div class="d-flex flex-wrap" style="gap: 0px 20px">
        <div>
          <v-text-field
            v-model="search"
            clearable
            outlined
            dense
            placeholder="Search name, email, or etc."
            prepend-inner-icon="mdi-magnify"
            color="primary"
            class="rounded-lg"
          ></v-text-field>
        </div>

        <div>
          <v-menu
            ref="dateMenu"
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            min-width="auto"
            offset-y
            nudge-top="20"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-text-field
                :value="dateRangeText"
                placeholder="Filter by Date Range"
                v-bind="attrs"
                height="42px"
                outlined
                dense
                append-icon="mdi-calendar"
                v-on="on"
                clearable
                readonly
                @click:clear="dateRange = []"
                class="rounded-lg"
              ></v-text-field>
            </template>
            <v-date-picker
              color="primary"
              v-model="dateRange"
              range
              no-title
              scrollable
              :max="maxDate"
              @input="onDateSelected"
            >
            </v-date-picker>
          </v-menu>
        </div>

        <div>
          <v-autocomplete
            :menu-props="{ bottom: true, offsetY: true }"
            append-icon="mdi-chevron-down"
            v-model="operations"
            :items="executive_list"
            :loading="executive_list_loading"
            item-text="name"
            item-value="id"
            clearable
            outlined
            dense
            placeholder="Filter by Operations"
            class="rounded-lg"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-if="data.item.name">
                  {{ data.item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span v-if="data.item.user.mobile">
                    {{ data.item.user.mobile_display }} |
                  </span>
                  {{ data.item.user.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <div>
          <v-autocomplete
            :menu-props="{ bottom: true, offsetY: true }"
            append-icon="mdi-chevron-down"
            v-model="feIr"
            :items="fe_ir_list"
            :search-input.sync="fe_ir_search"
            :loading="fe_ir_list_loading"
            item-text="name"
            item-value="id"
            clearable
            outlined
            dense
            no-filter
            placeholder="Filter by FE/IR"
            class="rounded-lg"
          >
            <template v-slot:no-data>
              <div class="mx-3 my-1 text-caption">
                <span v-if="fe_ir_list_loading">Searching...</span>
                <span v-else> No data available </span>
              </div>
            </template>

            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-if="data.item.name">
                  {{ data.item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span v-if="data.item.user.mobile">
                    {{ data.item.user.mobile_display }} |
                  </span>
                  {{ data.item.user.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <div>
          <v-autocomplete
            :menu-props="{ bottom: true, offsetY: true }"
            append-icon="mdi-chevron-down"
            v-model="vendor"
            :items="vendor_list"
            :search-input.sync="vendor_search"
            :loading="vendor_list_loading"
            item-text="name"
            item-value="id"
            clearable
            outlined
            dense
            no-filter
            placeholder="Filter by Vendor"
            class="rounded-lg"
          >
            <template v-slot:no-data>
              <div class="mx-3 my-1 text-caption">
                <span v-if="vendor_list_loading">Searching...</span>
                <span v-else> No data available </span>
              </div>
            </template>

            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-if="data.item.name">
                  {{ data.item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span v-if="data.item.user.mobile">
                    {{ data.item.user.mobile_display }} |
                  </span>
                  {{ data.item.user.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <div>
          <v-autocomplete
            auto-select-first
            :menu-props="{ bottom: true, offsetY: true, left: true }"
            append-icon="mdi-chevron-down"
            v-model="university"
            :items="university_list"
            :search-input.sync="university_search"
            :loading="university_list_loading"
            item-text="name"
            item-value="id"
            outlined
            dense
            clearable
            no-filter
            placeholder="Filter by University"
            class="rounded-lg"
          >
            <template v-slot:no-data>
              <div class="mx-3 my-1 text-caption">
                <span v-if="university_list_loading">Searching...</span>
                <span v-else> No data available </span>
              </div>
            </template>

            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-if="data.item.name">
                  {{ data.item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span v-if="data.item.user.mobile">
                    {{ data.item.user.mobile_display }} |
                  </span>
                  {{ data.item.user.email }}
                </v-list-item-subtitle> </v-list-item-content
              >0
            </template>
          </v-autocomplete>
        </div>
      </div>

      <v-sheet
        color="primary pa-3 d-flex justify-space-between align-center"
        rounded="lg"
        min-width="280px"
        style="gap: 20px"
        v-if="payout_balance && ['accounts'].includes(user.user_type)"
      >
        <div class="white--text">
          <div class="text-body-1">Payout Balance</div>
          <div class="font-weight-bold text-h6">
            {{ payout_balance.payout_balance }}
            <div
              v-if="payout_balance.is_payout_balance_requested"
              class="text-caption text-decoration-underline"
            >
              Request is in progress
            </div>
            <v-edit-dialog
              v-else
              @save="onBalanceRequestedSave"
              @close="onBalanceRequestedClose"
              large
              transition="slide-y-transition"
            >
              <div class="text-caption text-decoration-underline pointer">
                Request Balance
              </div>
              <template v-slot:input style="max-width: 100px">
                <div class="my-4">Request Payout Balance</div>
                <v-text-field
                  v-model="form.payout_balance"
                  label="Amount"
                  :rules="[$rules.required]"
                  height="42px"
                  type="number"
                  outlined
                  dense
                  hide-spin-buttons
                  @keydown="$rules.blockInvalidChar"
                  class="rounded-lg"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </div>
        </div>
        <v-icon color="grey lighten-1" size="50">mdi-currency-rupee</v-icon>
      </v-sheet>
    </div>

    <v-sheet elevation="3" class="rounded-b-0">
      <v-tabs
        height="125px"
        next-icon="mdi-chevron-right-circle"
        prev-icon="mdi-chevron-left-circle"
        v-model="activeTab"
        show-arrows
      >
        <v-tab v-for="(item, id) in tabs" :key="id" class="tab-width">
          <div>
            <v-icon
              size="20"
              style="margin-top: -25px; font-size: 45px"
              :color="item.color"
              >mdi-circle-small</v-icon
            >

            <div class="tab-total" style="margin-top: -10px">
              {{ item.value }}
            </div>
            <div class="tab-name">
              {{ item.label }}
            </div>
          </div>
        </v-tab>
      </v-tabs>
    </v-sheet>

    <v-divider />

    <payout-table
      ref="dataTable"
      @reloadData="getCountPayout(), reloadData()"
    />
  </div>
</template>
<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import payoutTable from "./payoutTable.vue";

export default {
  components: { payoutTable },
  data() {
    return {
      dateMenu: false,

      university_list: [],
      university_search: "",
      university_list_loading: false,

      executive_list: [],
      executive_list_loading: false,

      fe_ir_list: [],
      fe_ir_search: "",
      fe_ir_list_loading: false,

      vendor_list: [],
      vendor_search: "",
      vendor_list_loading: false,

      payout_balance: null,

      form: {
        payout_balance: 0,
      },

      tabs: [
        {
          label: `Pending`,
          key: "pending",
          value: 0,
          color: "#1149B7",
        },
        {
          label: `Successful`,
          key: "successful",
          value: 0,
          color: "#3DA922",
        },
        {
          label: `Cancelled`,
          key: "cancelled",
          value: 0,
          color: "red",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      getFilters: "payouts/filters",
      getSearch: "payouts/search",
      getActiveTab: "payouts/activeTab",
    }),

    activeTab: {
      get() {
        return this.getActiveTab.index;
      },
      set(value) {
        this.setActiveTab({ index: value, key: this.tabs[value].key });
      },
    },

    maxDate() {
      const today = this.$moment();
      return today.format("YYYY-MM-DD");
    },

    dateRangeText() {
      const _filter_by_date = this.dateRange.map((item) =>
        this.$moment(item).format("DD-MM-YYYY")
      );
      return _filter_by_date.join(" ~ ");
    },

    search: {
      get() {
        return this.getSearch;
      },
      set(value) {
        this.setSearch(value);
      },
    },

    dateRange: {
      get() {
        return this.getFilters.date;
      },
      set(value) {
        this.setFilters({ date: value });
      },
    },

    operations: {
      get() {
        return this.getFilters.operations;
      },
      set(value) {
        this.setFilters({ operations: value });
      },
    },

    feIr: {
      get() {
        return this.getFilters.fe_ir;
      },
      set(value) {
        this.setFilters({ fe_ir: value });
      },
    },

    vendor: {
      get() {
        return this.getFilters.vendor;
      },
      set(value) {
        this.setFilters({ vendor: value });
      },
    },

    university: {
      get() {
        return this.getFilters.university;
      },
      set(value) {
        this.setFilters({ university: value });
      },
    },
  },

  watch: {
    university_search: _.debounce(function (query) {
      if (this.university_list_loading) return;

      if (query && !this.university) {
        this.getUniversityList();
      }
    }, 1000),

    vendor_search: _.debounce(function (query) {
      if (this.vendor_list_loading) return;

      if (query && !this.vendor) {
        this.getVendorList();
      }
    }, 1000),

    fe_ir_search: _.debounce(function (query) {
      if (this.fe_ir_list_loading) return;

      if (query && !this.feIr) {
        this.getFeIrList();
      }
    }, 1000),
  },

  mounted() {
    this.getCountPayout();
    this.getExecutiveList();
    this.getPayoutBalance();
    this.$root.$on("onNavClicked", this.navClickHandler);
  },

  beforeDestroy() {
    this.$root.$off("onNavClicked", this.navClickHandler);
  },

  methods: {
    ...mapActions({
      setSearch: "payouts/setSearch",
      setFilters: "payouts/setFilters",
      setActiveTab: "payouts/setActiveTab",
      showSnackbar: "app/showSnackbar",
    }),

    navClickHandler(navKey) {
      if (navKey === "payout_management") {
        this.reloadData();
        this.$refs.dataTable.updateRoute();
      }
    },

    reloadData() {
      this.getCountPayout();
      this.$refs.dataTable.getListData();
    },

    onDateSelected() {
      if (this.dateRange.length === 2) this.dateMenu = false;
    },

    onBalanceRequestedClose() {
      this.form.payout_balance = 0;
    },

    onBalanceRequestedSave() {
      const data = { amount: this.form.payout_balance };
      const onSuccess = (res) => {
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.getPayoutBalance();
      };

      return this.$request(
        this.$keys.POST,
        this.$urls.team.payoutBalance.request,
        { data, onSuccess }
      );
    },

    getPayoutBalance() {
      const onSuccess = (res) => {
        this.payout_balance = res.data.data;
      };
      return this.$request(this.$keys.GET, this.$urls.team.payoutBalance.get, {
        onSuccess,
      });
    },

    getExecutiveList() {
      this.executive_list_loading = true;

      const params = {
        apply_pagination: false,
        list_type: "assign_operations",
      };

      const onSuccess = (res) => {
        this.executive_list = res.data.data;
      };

      const onFinally = () => {
        this.executive_list_loading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.team.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getCountPayout() {
      const onSuccess = (res) => {
        this.tabs.forEach((tab) => {
          tab.value = res.data.data[tab.key];
        });
        this.$forceCompute("tabs");
      };

      const params = {
        search: this.search,
        executive_id: this.operations,
        fe_ir_id: this.feIr,
        vendor_id: this.vendor,
        university_id: this.university,
      };

      if (this.dateRange) {
        if (this.dateRange.length === 2) {
          params["start_date"] = this.dateRange[0];
          params["end_date"] = this.dateRange[1];
        } else {
          params["date"] = this.dateRange[0];
        }
      }

      return this.$request(this.$keys.GET, this.$urls.case.payout.count, {
        params,
        onSuccess,
      });
    },

    getFeIrList() {
      this.fe_ir_list_loading = true;

      const params = new URLSearchParams();
      params.append("search", this.fe_ir_search);
      params.append("role", "field_executive");
      params.append("role", "internal_resource");

      const onSuccess = (res) => {
        this.fe_ir_list = res.data.data;
      };

      const onFinally = () => {
        this.fe_ir_list_loading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.agent.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getVendorList() {
      this.vendor_list_loading = true;

      const params = {
        apply_pagination: false,
        role: "vendor",
        search: this.vendor_search,
      };

      const onSuccess = (res) => {
        this.vendor_list = res.data.data;
      };

      const onFinally = () => {
        this.vendor_list_loading = false;
      };

      this.$request(this.$keys.GET, this.$urls.agent.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getUniversityList() {
      this.university_list_loading = true;

      const params = {
        search: this.university_search,
      };
      const onSuccess = (res) => {
        this.university_list = res.data.data;
      };

      const onFinally = () => {
        this.university_list_loading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.university.list, {
        params,
        onSuccess,
        onFinally,
      });
    },
  },
};
</script>
<style scoped>
.tabs {
  width: 200px;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
}
.tab-total {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
}
.tab-name {
  word-wrap: break-word;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.active-class {
  background: #f2ffff;
}
</style>
