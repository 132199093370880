export default [
  {
    sortable: false,
    text: "#",
    value: "sn",
    width: 120,
  },
  {
    sortable: false,
    text: "Report Type",
    value: "report_type",
    width: 120,
  },
  {
    sortable: false,
    text: "Filters",
    value: "filters",
    width: 350,
  },
  {
    sortable: false,
    text: "Report Date",
    value: "date",
    width: 120,
  },
  {
    sortable: false,
    text: "Report Status",
    value: "report_status",
  },
  {
    sortable: false,
    text: "Action",
    value: "action",
  },
];
