<template>
  <div>
    <v-data-table
      :loading="loading"
      loading-text="Please wait.... Loading"
      :headers="headers"
      :items="items"
      hide-default-footer
      mobile-breakpoint
      class="elevation-3 rounded-t-0"
    >
      <template v-slot:[`item.created`]="{ item }">
        {{ $utils.formatDate(item.created) }}
      </template>

      <template v-slot:[`item.payout_date`]="{ item }">
        <div v-if="item.payout_date">
          {{ $utils.formatDate(item.payout_date) }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.status_date`]="{ item }">
        <div v-if="item.status_datetime">
          {{ $utils.formatDate(item.status_datetime) }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.case_id`]="{ item }">
        {{ item.case.case_id }}
      </template>

      <template v-slot:[`item.client`]="{ item }">
        <div>
          {{ item.case.client.name }}
          <div v-if="item.case.client.email">{{ item.case.client.email }}</div>
          <div v-if="item.case.client.mobile">
            {{ item.case.client.mobile_display }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.assigned_operations`]="{ item }">
        <div v-if="item.case.operations">
          {{ item.case.operations.name }}
          <div v-if="item.case.operations.email">
            {{ item.case.operations.email }}
          </div>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.payout_for`]="{ item }">
        <div v-if="item.payout_for === $keys.FE_IR">
          <div v-if="item.case.fe_ir">
            <v-chip small class="font-weight-medium mb-1">
              {{ item.case.fe_ir.role_display }}
            </v-chip>
            {{ item.case.fe_ir.name }}
            <div v-if="item.case.fe_ir.email">
              {{ item.case.fe_ir.email }}
            </div>

            <v-edit-dialog
              transition="slide-y-transition"
              v-if="
                item.case.fe_ir.account_number ||
                item.case.fe_ir.upi_id ||
                item.case.fe_ir.bank_name ||
                item.case.fe_ir.bank_ifsc_code ||
                item.case.fe_ir.bank_branch_name
              "
            >
              <div class="text-caption text-decoration-underline">
                View Bank Details
              </div>
              <template v-slot:input>
                <div class="my-2">
                  <div>Bank Details</div>
                  <v-divider class="my-1" />
                  <div class="text-caption">
                    <div v-if="item.case.fe_ir.account_number">
                      <span class="font-weight-medium">AC No:</span>
                      {{ item.case.fe_ir.account_number }}
                    </div>
                    <div v-if="item.case.fe_ir.upi_id">
                      <span class="font-weight-medium">UPI:</span>
                      {{ item.case.fe_ir.upi_id }}
                    </div>
                    <div v-if="item.case.fe_ir.bank_name">
                      <span class="font-weight-medium">Bank Name:</span>
                      {{ item.case.fe_ir.bank_name }}
                    </div>
                    <div v-if="item.case.fe_ir.bank_ifsc_code">
                      <span class="font-weight-medium">IFSC Code:</span>
                      {{ item.case.fe_ir.bank_ifsc_code }}
                    </div>
                    <div v-if="item.case.fe_ir.bank_branch_name">
                      <span class="font-weight-medium">Branch Name:</span>
                      {{ item.case.fe_ir.bank_branch_name }}
                    </div>
                  </div>
                </div>
              </template>
            </v-edit-dialog>
          </div>

          <v-chip v-else small class="font-weight-medium mb-1">FE/IR</v-chip>
        </div>

        <div v-else-if="item.payout_for === $keys.VENDOR">
          <v-chip small class="font-weight-medium mb-1">Vendor</v-chip>
          <div v-if="item.case.vendor">
            {{ item.case.vendor.name }}
            <div v-if="item.case.vendor.email">
              {{ item.case.vendor.email }}
            </div>

            <v-edit-dialog
              transition="slide-y-transition"
              v-if="
                item.case.vendor.account_number ||
                item.case.vendor.upi_id ||
                item.case.vendor.bank_name ||
                item.case.vendor.bank_ifsc_code ||
                item.case.vendor.bank_branch_name
              "
            >
              <div class="text-caption text-decoration-underline">
                View Bank Details
              </div>
              <template v-slot:input>
                <div class="my-2">
                  <div>Bank Details</div>
                  <v-divider class="my-1" />
                  <div class="text-caption">
                    <div v-if="item.case.vendor.account_number">
                      <span class="font-weight-medium">AC No:</span>
                      {{ item.case.vendor.account_number }}
                    </div>
                    <div v-if="item.case.vendor.upi_id">
                      <span class="font-weight-medium">UPI:</span>
                      {{ item.case.vendor.upi_id }}
                    </div>
                    <div v-if="item.case.vendor.bank_name">
                      <span class="font-weight-medium">Bank Name:</span>
                      {{ item.case.vendor.bank_name }}
                    </div>
                    <div v-if="item.case.vendor.bank_ifsc_code">
                      <span class="font-weight-medium">IFSC Code:</span>
                      {{ item.case.vendor.bank_ifsc_code }}
                    </div>
                    <div v-if="item.case.vendor.bank_branch_name">
                      <span class="font-weight-medium">Branch Name:</span>
                      {{ item.case.vendor.bank_branch_name }}
                    </div>
                  </div>
                </div>
              </template>
            </v-edit-dialog>
          </div>
        </div>

        <div v-else-if="item.payout_for === $keys.UNIVERSITY">
          <v-chip small class="font-weight-medium mb-1">University</v-chip>
          <div v-if="item.case.university">
            {{ item.case.university.name }}
            <div v-if="item.case.university.full_address">
              {{ item.case.university.full_address }}
            </div>

            <v-edit-dialog
              transition="slide-y-transition"
              v-if="
                item.case.university.account_number ||
                item.case.university.upi_id ||
                item.case.university.bank_name ||
                item.case.university.bank_ifsc_code ||
                item.case.university.bank_branch_name
              "
            >
              <div class="text-caption text-decoration-underline">
                View Bank Details
              </div>
              <template v-slot:input>
                <div class="my-2">
                  <div>Bank Details</div>
                  <v-divider class="my-1" />
                  <div class="text-caption">
                    <div v-if="item.case.university.account_number">
                      <span class="font-weight-medium">AC No:</span>
                      {{ item.case.university.account_number }}
                    </div>
                    <div v-if="item.case.university.upi_id">
                      <span class="font-weight-medium">UPI:</span>
                      {{ item.case.university.upi_id }}
                    </div>
                    <div v-if="item.case.university.bank_name">
                      <span class="font-weight-medium">Bank Name:</span>
                      {{ item.case.university.bank_name }}
                    </div>
                    <div v-if="item.case.university.bank_ifsc_code">
                      <span class="font-weight-medium">IFSC Code:</span>
                      {{ item.case.university.bank_ifsc_code }}
                    </div>
                    <div v-if="item.case.university.bank_branch_name">
                      <span class="font-weight-medium">Branch Name:</span>
                      {{ item.case.university.bank_branch_name }}
                    </div>
                  </div>
                </div>
              </template>
            </v-edit-dialog>
          </div>
        </div>

        <div v-else>
          <v-chip small class="font-weight-medium">
            {{ item.payout_for_display }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.amount`]="{ item }">
        <div v-if="item.amount">
          <span v-if="item.is_splitted">
            <div>FE/IR: ₹{{ item.fe_ir_amount }}</div>
            <div>University: ₹{{ item.university_amount }}</div>
            <div>Total: ₹{{ item.amount }}</div>
          </span>
          <span v-else>₹{{ item.amount }}</span>
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          small
          class="lighten-5 text--darken-2 font-weight-bold text-capitalize"
          :class="
            item.status == 'successful'
              ? 'success success--text'
              : item.status == 'pending'
              ? 'blue blue--text'
              : 'error error--text'
          "
        >
          {{ item.status }}</v-chip
        >
      </template>

      <template v-slot:[`item.reject_reason`]="{ item }">
        <div v-if="item.reject_reason">
          {{ item.reject_reason }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.remarks`]="{ item }">
        <div v-if="item.remarks || item.approve_remarks">
          <div class="mb-1" v-if="item.remarks">
            {{ item.remarks }}
          </div>
          <div v-if="item.approve_remarks">
            <div class="font-weight-medium text-caption">Approval Remarks:</div>
            <div>
              {{ item.approve_remarks }}
            </div>
          </div>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex justify-center" style="gap: 10px">
          <v-btn
            small
            outlined
            color="primary"
            class="text-capitalize rounded-lg"
            height="32px"
            v-if="item.actions.includes('view_case')"
            @click="viewCase(item.case.id)"
          >
            View Case
          </v-btn>

          <v-btn
            small
            outlined
            color="primary"
            class="text-capitalize rounded-lg"
            height="32px"
            @click="approvePayoutDialog({ show: true, payout_id: item.id })"
            v-if="item.actions.includes('payout_approve')"
          >
            Approve
          </v-btn>
          <v-btn
            small
            outlined
            color="primary"
            class="text-capitalize rounded-lg"
            height="32px"
            @click="rejectPayoutDialog({ show: true, payout_id: item.id })"
            v-if="item.actions.includes('payout_reject')"
          >
            Reject
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-container
      class="d-flex justify-space-between align-center px-0 mb-0 mt-4"
      fluid
    >
      <div class="d-flex align-center text-subtitle-2">
        <div class="mr-2">Rows per page</div>
        <div style="width: 75px">
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            :items="itemsPerPageList"
            v-model="page_count"
            hide-details
            dense
            solo
            color="primary"
            class="rounded-lg"
          ></v-select>
        </div>
        <div class="ml-6">
          Results: {{ pageStart }} - {{ pageEnd }} of {{ total_count }}
        </div>
      </div>
      <v-pagination
        v-model="page_number"
        :length="page_total"
        :total-visible="5"
        color="primary"
      >
      </v-pagination>
    </v-container>

    <PayoutApproveDialog @submitted="reloadData()" />
    <PayoutRejectDialog @submitted="reloadData()" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

import { payoutTable } from "@/headers";
import PayoutApproveDialog from "@/components/CaseManagement/Payout/ApproveDialog";
import PayoutRejectDialog from "@/components/CaseManagement/Payout/RejectDialog";

export default {
  components: { PayoutApproveDialog, PayoutRejectDialog },
  data() {
    return {
      items: [],
      loading: false,

      page_total: 1,
      page_number: 1,
      total_count: 0,
      itemsPerPageList: [5, 10, 15, 20, 30, 50],
      page_count: 10,
    };
  },

  watch: {
    page_count() {
      this.getListData();
    },

    page_number() {
      this.getListData();
      this.updateRoute();
    },

    activeTab: {
      handler() {
        this.reloadData();
      },
      deep: true,
    },

    search() {
      this.debouncedReloadData();
    },

    filters: {
      handler() {
        this.reloadData();
        this.updateRoute();
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters({
      search: "payouts/search",
      filters: "payouts/filters",
      activeTab: "payouts/activeTab",
    }),

    headers() {
      return payoutTable[this.activeTab.key];
    },

    pageStart() {
      let start = (this.page_number - 1) * this.page_count + 1;
      if (start + 1 > this.total_count) start = this.total_count;
      return start;
    },

    pageEnd() {
      let end = this.pageStart + this.page_count - 1;
      if (end > this.total_count) end = this.total_count;
      return end;
    },
  },

  created() {
    const query = this.$route.query;

    if (query.page) {
      this.page_number = Number(query.page);
    }

    if (query.search) {
      this.setSearch(String(query.search));
    }

    if (query.executive) {
      this.setFilters({ operations: Number(query.executive) });
    }

    if (query.fe_ir) {
      this.setFilters({ fe_ir: Number(query.fe_ir) });
    }

    if (query.vendor) {
      this.setFilters({ vendor: Number(query.vendor) });
    }

    if (query.university) {
      this.setFilters({ university: Number(query.university) });
    }

    if (query.date) {
      this.setFilters({ date: [String(query.date)] });
    }

    if (query.start_date && query.end_date) {
      this.setFilters({
        date: [String(query.start_date), String(query.end_date)],
      });
    }

    this.updateRoute();
  },

  mounted() {
    this.getListData();
  },

  methods: {
    ...mapActions({
      setSearch: "payouts/setSearch",
      setFilters: "payouts/setFilters",
      approvePayoutDialog: "payouts/toggleApproveDialog",
      rejectPayoutDialog: "payouts/toggleRejectDialog",
    }),

    debouncedReloadData: _.debounce(function () {
      this.reloadData();
      this.updateRoute();
    }, 1000),

    reloadData() {
      this.$emit("reloadData");
      this.getListData();
    },

    updateRoute() {
      const query = {};

      if (this.search) {
        query.search = String(this.search);
      }

      if (this.page_number) {
        query.page = Number(this.page_number);
      }

      if (this.filters.operations) {
        query.executive = Number(this.filters.operations);
      }

      if (this.filters.fe_ir) {
        query.fe_ir = Number(this.filters.fe_ir);
      }

      if (this.filters.vendor) {
        query.vendor = Number(this.filters.vendor);
      }

      if (this.filters.university) {
        query.university = Number(this.filters.university);
      }

      if (this.filters.date.length) {
        if (this.filters.date.length === 2) {
          query.start_date = String(this.filters.date[0]);
          query.end_date = String(this.filters.date[1]);
        } else {
          query.date = String(this.filters.date[0]);
        }
      }

      this.$router
        .replace({
          path: this.$route.path,
          query: query,
        })
        .catch((error) => error);
    },

    viewCase(id) {
      this.$router.push({ name: "caseDetail", params: { id } });
    },

    getListData() {
      this.items = [];
      this.loading = true;

      const onSuccess = (res) => {
        this.page_total = res.data.page_info.page_total;
        this.page_number = res.data.page_info.page_number;
        this.total_count = res.data.page_info.total_count;
        this.items = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        page_number: this.page_number,
        page_count: this.page_count,
        payout_status: this.activeTab.key,
        search: this.search,
        executive_id: this.filters.operations,
        fe_ir_id: this.filters.feIr,
        vendor_id: this.filters.vendor,
        university_id: this.filters.university,
      };

      if (this.filters.date) {
        if (this.filters.date.length === 2) {
          params["start_date"] = this.filters.date[0];
          params["end_date"] = this.filters.date[1];
        } else {
          params["date"] = this.filters.date[0];
        }
      }

      return this.$request(this.$keys.GET, this.$urls.case.payout.list, {
        params,
        onSuccess,
        onFinally,
        cancel: true,
      });
    },
  },
};
</script>
<style scoped>
.v-data-table.dataTable.theme--light {
  min-width: 100%;
}
.footer-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #333333;
}
.alignSelf-center {
  align-self: center;
}
</style>
<style>
.tableHeader-text {
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  height: 62px !important;
  text-transform: uppercase;
  color: #333333 !important;
}
.tableHeader-bg {
  background: #f3f3f3 !important;
}

.v-data-table.dataTable.theme--light {
  min-width: 1200px;
}
.dataTable-div {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .v-data-table.dataTable.theme--light {
    min-width: 1200px;
  }
  .dataTable-div {
    width: 100%;
    overflow-x: scroll;
  }
}
</style>
