export default {
  pending: [
    {
      sortable: false,
      text: "Date",
      value: "date",
    },
    {
      sortable: false,
      text: "Case id",
      value: "case_id",
      width: 120,
    },
    {
      sortable: false,
      text: "Client",
      value: "client",
      width: 120,
    },
    {
      sortable: false,
      text: "Clause",
      value: "clause",
    },
    {
      sortable: false,
      text: "Amount",
      value: "amount",
      width: 160,
    },
    {
      sortable: false,
      text: "Status",
      value: "status",
    },
    {
      sortable: false,
      text: "Remarks",
      value: "remarks",
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],

  successful: [
    {
      sortable: false,
      text: "Date",
      value: "date",
    },
    {
      sortable: false,
      text: "Case id",
      value: "case_id",
      width: 120,
    },
    {
      sortable: false,
      text: "Client",
      value: "client",
      width: 120,
    },
    {
      sortable: false,
      text: "Clause",
      value: "clause",
    },
    {
      sortable: false,
      text: "Amount",
      value: "amount",
      width: 160,
    },
    {
      sortable: false,
      text: "Status",
      value: "status",
    },
    {
      sortable: false,
      text: "Transaction Id",
      value: "transaction_id",
      width: 140,
    },
    {
      sortable: false,
      text: "Refund Date",
      value: "refund_date",
      width: 140,
    },
    {
      sortable: false,
      text: "Remarks",
      value: "remarks",
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],

  cancelled: [
    {
      sortable: false,
      text: "Date",
      value: "date",
    },
    {
      sortable: false,
      text: "Case id",
      value: "case_id",
      width: 120,
    },
    {
      sortable: false,
      text: "Client",
      value: "client",
      width: 120,
    },
    {
      sortable: false,
      text: "Clause",
      value: "clause",
    },
    {
      sortable: false,
      text: "Amount",
      value: "amount",
      width: 160,
    },
    {
      sortable: false,
      text: "Status",
      value: "status",
    },
    {
      sortable: false,
      text: "Cancelled Reason",
      value: "reject_reason",
      width: 150,
    },
    {
      sortable: false,
      text: "Remarks",
      value: "remarks",
    },
    {
      sortable: false,
      text: "Action",
      value: "action",
    },
  ],
};
